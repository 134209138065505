import React, { Component } from 'react';
import { Container, Row } from "reactstrap";
import SectionTitle from "../common/section-title";
import ProcessBox from './Process-box';
import { Link } from 'react-router-dom';

class Pricing extends Component {

    state = {
        pricings: [
            {
                title: "Complete loan application Form",
                num: 1,
                description: "The following documents will be needed; ID, three months bank statement & latest pay slip "
            },
            {
                title: "Complete mandate Form",
                num: 2,
                description: "We use debit check for collections, this step is to allow us to debit on your bank account"
            },
            {
                title: "Get your cash",
                num: 3,
                description: "The loan amount will be directly paid into your bank account immediately after debi check acceptance"
            }
        ],
    }

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="pricing">
                    <Container>
                        <SectionTitle title2="Loan application steps" />

                        <Row className="mt-5 pt-2">
                            <ProcessBox pricings={this.state.pricings} />
                        </Row>

                        <div className="text-center">
                            <a href="https://app.ritikefinance.com/login" className="btn btn-custom text-uppercase  mt-5"> APPLY NOW </a>
                        </div>

                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Pricing;