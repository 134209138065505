import React, { Component } from 'react';
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Footer from '../../components/Footer/footer';
import GetStarted from "../../components/Get Started/get-started";
import Services from '../../components/Services/services';
import Process from '../../components/Process/Process';

class Index3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pos: document.documentElement.scrollTop,
            imglight: true,
            navClass: ""
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > this.state.pos) {
            this.setState({ navClass: "darkheader", imglight: false });
        }
        else {
            this.setState({ navClass: "", imglight: true });
        }
    };

    render() {
        return (
            <React.Fragment>
                <NavbarPage navclass={this.state.navClass} imglight={this.state.imglight} />
                <Section />
                <Process />
                <Services />
                <GetStarted />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Index3;