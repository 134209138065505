import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import FooterLinks from "./footer-links";

class Footer extends Component {
    state = {
        footerItems: [
            {
                title: "ritike finance",
                links: [
                    { linkTitle: "HOME", link: "#home" },
                    { linkTitle: "SERVICES", link: "#service" },
                    { linkTitle: "ABOUT", link: "#about" },
                    { linkTitle: "CONTACT", link: "#contact" },
                    { linkTitle: "LOGIN", link: "#login" },
                ]
            }
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light">
                    <Container>
                        <Row>
                            {
                                this.state.footerItems.map((item, key) =>
                                    <Col lg="3" key={key}>
                                        <p className="text-uppercase footer-title">{item.title}</p>
                                        <ul className="list-unstyled company-sub-menu">
                                            {
                                                item.links.map((fLink, key) =>
                                                    <li key={key}><Link to={fLink.link}>{fLink.linkTitle}</Link></li>
                                                )
                                            }
                                        </ul>
                                    </Col>
                                )
                            }
                            <Col lg="3">
                                <p className="text-uppercase footer-title">Contact Us</p>
                                <div className="contact-us">
                                    <p>info@ritikefinance.com</p>
                                    <p>Phone : (+27) 0671802864 </p>
                                </div>
                                <ul className="footer-icons list-inline mt-4">
                                    <li className="list-inline-item"><Link to="#" className=""><i className="mdi mdi-facebook"></i></Link></li>
                                    <li className="list-inline-item"><Link to="#" className=""><i className="mdi mdi-twitter"></i></Link></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <FooterLinks />
            </React.Fragment>
        );
    }
}

export default Footer;