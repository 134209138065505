import React, { Component } from 'react';
import { Col, Card } from "reactstrap";

class PricingBox extends Component {
    render() {
        return (
            <React.Fragment>
                {
                    this.props.pricings.map((service, key) =>
                        <Col lg="4" className="mt-3" key={key}>
                            <Card className="service-box clearfix p-4 steps__box">
                                <div className="text-center">
                                    <div>
                                        <h2>{service.num}</h2>
                                    </div>
                                    <h5 className="font-weight-bold">{service.title}</h5>
                                    <p className="text-muted mb-0">{service.description}</p>
                                </div>
                            </Card>
                        </Col>
                    )
                }
            </React.Fragment>
        );
    }
}

export default PricingBox;