import React, { Component } from 'react';

class FooterLinks extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="footer-alt bg-dark">
                    <p className="copy-rights">  Developed by: Mulozwi Technologies 2023 <a href="http://www.mulozwitech.co.za/"> mulozwitech</a> </p>
                </div>
            </React.Fragment>
        );
    }
}

export default FooterLinks;