import React, { useState } from 'react';
import { Col, Container, Row } from "reactstrap";

import "react-rangeslider/lib/index.css";

export default function Section() {

    const [amount, setAmount] = useState()
    const [step, setStep] = useState()
    const percentage = step > 15 ? 30 : 22.5;
    const Total = amount * percentage;
    const Total_pay_back = Total / 100;
    const result = amount + Total_pay_back

    return (
        <React.Fragment>
            <section className="home-half" id="home">
                <div className="bg-overlay"></div>
                <div className="home-center">
                    <div className="home-desc-center">
                        <Container>
                            <Row className="vertical-content">
                                <Col lg="12" className="text-left mt-3">
                                    <h6 className="home-title text-white">Flexible, short term loans that get you going </h6>
                                    <p className="pt-4 home-sub-title text-white">
                                        You can get up to R2000 in your account today and have 30 days to repay.
                                    </p>
                                    <div className="watch-video pt-4">
                                        <a href="#service" className="btn btn-custom text-uppercase">  LEARN MORE</a>
                                    </div>
                                </Col>

                                {/* <Col lg="5" className="mt-3">
                                    <div className="home-registration-form bg-white p-5">
                                        <h2 className="form-title mb-4"> How much do you need ? </h2>

                                        <div className="p-3">
                                            <h5 className="font-size-14 mb-3 mt-0">Amount</h5>
                                            <span className="float-left mt-4">100</span>
                                            <span className="float-right  mt-4">2000</span>
                                            <Slider
                                                min={100}
                                                max={2000}
                                                value={amount}
                                                orientation="horizontal"
                                                onChange={value => { setAmount(value) }}
                                            />
                                        </div>

                                        <div className="p-3">
                                            <h5 className="font-size-14 mb-3 mt-0">Days</h5>
                                            <span className="float-left mt-4">5</span>
                                            <span className="float-right  mt-4">30</span>
                                            <Slider
                                                min={1}
                                                max={30}
                                                value={step}
                                                step={1}
                                                orientation="horizontal"
                                                onChange={value => { setStep(value) }}
                                            />
                                        </div>

                                        <div className='my-3'>
                                            <Row>
                                                <Col md={6}> <h5> Total Pay Back </h5> </Col>
                                                <Col md={6}> <h5> R {result ? result : 0} </h5>  </Col>
                                            </Row>
                                        </div>

                                        <a href="https://app.ritikefinance.com/login" className="btn btn-custom w-100 text-uppercase"> APPLY FOR A LOAN </a>
                                    </div>
                                </Col> */}
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}