import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";
import { GrMoney } from 'react-icons/gr';
import { GiNetworkBars } from 'react-icons/gi';

class Services extends Component {
    state = {
        services: [
            { title: "Loan Service", icon: GrMoney, description: "Get your loan approved in less than 10 minutes" },
            { title: "Ritike Connect", icon: GiNetworkBars, description: "Stay in touch and top up airtime & data   from our portal in just few minutes" },
        ],
    }
    render() {
        return (
            <React.Fragment>
                <section id="service" className="section">
                    <Container>
                        <SectionTitle title1="Our" title2="Services" />
                        <Row className="pt-4 mt-4">
                            <i class="fas fa-hand-holding-usd"></i>
                            <ServiceBox services={this.state.services} />
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Services;